import React,{useState, useEffect} from 'react';
import SideBar from '../../Component/SideBar';
import Header from '../../Component/Header';
import axios from '../../API/Axios'

export default function Contact() {
    const [contactData, setcontactData] = useState([]);

    useEffect(() => {
        getContactData();
    }, []);

    const getContactData = ()=>{
    axios.get('/v1/Contact/view-contact.php').then(function (response) {
        // handle success
        console.log(response.data);
        setcontactData(response.data.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    }
  return (
    <div className="ecaps-page-wrapper">
      <SideBar></SideBar>
      <div className="ecaps-page-content">
        <Header></Header>

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-2">View Contact List</h4>
                    <div class="table-responsive">
                    <table id="example" class="table dt-responsive nowrap w-100">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>City</th>
                            <th>Purpose</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody id="dealersortable">
                        {contactData.length > 0 ?
                            contactData.map((element,index)=>{
                                return(<tr>
                                <td>{element.contact_id}</td>
                                <td>{element.contact_name}</td>
                                <td>{element.contact_email}</td>
                                <td>{element.contact_phone}</td>
                                <td>{element.contact_subject}</td>
                                <td>{element.contact_message}</td>
                                <td><img style={{height: "20px",width: "20px"}}  src="./img/delete.png" /></td>
                            </tr>);
                            })
                        :""}
                        
                        </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
