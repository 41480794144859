import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Bookings from "./Pages/Booking/Bookings";
import Contact from "./Pages/Contact/Contact";


function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Dashboard />} />
          <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Dashboard />} />
          <Route path={`${process.env.PUBLIC_URL}/bookings`} element={<Bookings />} />
          <Route path={`${process.env.PUBLIC_URL}/contacts`} element={<Contact />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

export default App;
