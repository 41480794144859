import { combineReducers } from 'redux';

const initialState = {
  username: null,
  isLoggedIn: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        username: action.payload.username,
        isLoggedIn: true,
      };
    case 'LOGIN_INVALID' :
      return {
        ...state,
        isLoggedIn: false,
      };
    case 'LOGOUT_SUCCESS':
      console.log("logout reducer");
      return {
        ...state,
        username: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  user: userReducer,
});

export default rootReducer;