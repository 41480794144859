import React from 'react';
import { Link } from 'react-router-dom';

export default function SideBar() {
  return (
    <div id="sidebar" className="ecaps-sidemenu-area">
        <div className="ecaps-logo">
            <a href="../dashboard/Dashboard" style={{textAlign: "center",display: "grid",margin: "auto"}}>
            </a>
        </div>

        <div className="ecaps-sidenav" id="ecapsSideNav">
            <div className="side-menu-area">
                <nav>
                    <ul className="sidebar-menu" data-widget="tree" style={{paddingLeft:"0px",paddingTop:"50px"}}>
                    <h6 className='text-center'>Admin</h6>
                        <li className="sidemenu-user-profile">
                        </li>
                        <li className="active"><Link to="/dashboard"><i className="fa fa-home"></i> <span>Dashboard</span></Link></li>
                        {/* <li><Link to="/bookings"><i className="fa fa-info-circle"></i> <span>Booking</span></Link></li> */}
                        <li><Link to="/contacts"><i className="fa fa-phone"></i> <span>Contact</span></Link></li>
                        
    
                            <li><a><i className="fa fa-unlock-alt"></i> <span>Change Password</span></a></li>
                        
                    </ul>
                </nav>
            </div>
        </div>
    </div>
  );
}
