import React from 'react';
import { Outlet, Navigate } from 'react-router-dom'; 
import { useSelector } from 'react-redux'; 

const PrivateRoute = ({element: Component, ...rest}) => {
    const isLoggedIn=localStorage.getItem('Active');
    return (
        isLoggedIn ?
                <Outlet {...rest} />
             : <Navigate to={`${process.env.PUBLIC_URL}/login`} />
    );
};

export default PrivateRoute;