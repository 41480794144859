import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../API/Axios';

function* loginSaga(action) {
  try {
    const response = yield call(axios.post, '/v1/Auth/login.php', action.payload);
    console.log(response.data.code);
    if(response.data.code == 100){
      console.log("success");
      localStorage.setItem('Active', true);
      yield put({ type: 'LOGIN_SUCCESS', payload: action.payload });
    }
    else{
      yield put({ type: 'LOGIN_INVALID', payload: action.payload });
    }
  } catch (error) {
    const message = error.response ? error.response.data.message : 'An error occurred';
    yield put({ type: 'LOGIN_ERROR', payload: { message } });
  }
}


function* logoutSaga() {
  // perform any necessary cleanup or API calls here
  localStorage.removeItem('Active');
  yield put({ type: 'LOGOUT_SUCCESS' });
}

function* rootSaga() {
  yield takeLatest('LOGIN', loginSaga);
  yield takeLatest('LOGOUT', logoutSaga);
}

export default rootSaga;
