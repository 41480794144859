import axios from 'axios';

const instant = axios.create({
  baseURL: 'http://accreteit.com/react-admin/api',
  timeout: 5000,
  headers: {
    'Token': `admin`,
  },
});

export default instant;
