import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch} from 'react-redux';
import { logout } from '../Store/actions';

export default function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = ()=>{
        dispatch(logout());
        navigate('/login')
    }
  return (
    <header id="header" class="top-header-area d-flex align-items-center justify-content-between">
        <div class="left-side-content-area d-flex align-items-center">
            <div class="mobile-logo mr-3 mr-sm-4">
                <Link to="/dashboard"><img src="../img/core-img/logo.png" alt="Mobile Logo" /></Link>
            </div>

            <div class="ecaps-triggers mr-1 mr-sm-3">
                <div class="menu-collasped" id="menuCollasped">
                    <i class="fa fa-bars"></i>
                </div>
                <div class="mobile-menu-open" id="mobileMenuOpen">
                    <i class="fa fa-bars"></i>
                </div>
            </div>

        </div>

        <div class="right-side-navbar d-flex align-items-center justify-content-end">
            <div class="right-side-trigger" id="rightSideTrigger">
                <i class="ti-align-left"></i>
                
            </div>

            <ul class="right-side-content d-flex align-items-center">
            
                <a onClick={handleLogout} class="dropdown-item"><img style={{height: "30px",width: "30px"}}  src="img/power.png" /></a>
            </ul>
        </div>
    </header>
  );
}
