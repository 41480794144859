import React from 'react';
import Header from '../../Component/Header';
import SideBar from '../../Component/SideBar';

export default function Dashboard() {
  return (
    <div className="ecaps-page-wrapper">
        <SideBar></SideBar>
    <div className="ecaps-page-content">
        <Header></Header>
        <div className="row">
            <div className="col-12">
                <div className="dahboard-header-area d-sm-flex align-items-center justify-content-between">
                    <div className="dahboard-header-title d-flex align-items-center">
                        <h6 className="mb-0 mr-3">Dashboard</h6>
                    </div>

                </div>
            </div>
        </div>

        <div className="main-content dashboard-pt">
            <div className="container-fluid">
                <div className="row">
                
                    <div className="col-xl-3">
                    {/* <a>
                        <div className="card" style={{height: "140px"}}>
                            <div className="card-body">
                                <div className="chartjs-size-monitor">
                                    <div className="chartjs-size-monitor-expand">
                                        <div className=""></div>
                                    </div>
                                    <div className="chartjs-size-monitor-shrink">
                                        <div className=""></div>
                                    </div>
                                </div>
                                <h4 className="card-title">Total Members</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="text-dark font-18 mb-0">
                                    
                                    </h2>
                                    
                                </div>
                            </div>
                        </div>
                    </a> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}
