import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

// Create the Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the Redux store with the Saga middleware
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

// Run the root Saga
sagaMiddleware.run(rootSaga);

export default store;
