import React from "react";
import SideBar from "../../Component/SideBar";
import Header from "../../Component/Header";


export default function Bookings() {
  return (
    <div className="ecaps-page-wrapper">
      <SideBar></SideBar>
      <div className="ecaps-page-content">
        <Header></Header>

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-2">View Booking List</h4>
                    <div class="table-responsive">
                    <table striped bordered hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Dealer</th>
                          </tr>
                        </thead>

                        <tbody id="dealersortable">
                          <tr class="cate">
															<td>Demo Dealer</td>
                              <td>Demo City</td>
                              <td>Demo Area</td>
                              <td>Demo Address</td>
                           
													</tr> 
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
