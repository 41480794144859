import React from 'react';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Store/actions';

export default function Login() {
    const navigate = useNavigate();
    const isLoggedIn=useSelector(state => state.user.isLoggedIn);
    const dispatch = useDispatch();
    const initialValues = {
        username: '',
        password: '',
        remember: true,
      };
    
      const validationSchema = Yup.object({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      });
    
    const handleLogin = (values, actions) => {
        dispatch(login(values));
        actions.setSubmitting(false);
    };

    console.log('isLoggedIn',isLoggedIn);
  return (
    !isLoggedIn ? <div>
      <div className="main-content- h-100vh">
        <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center">
                <div className="col-sm-10 col-lg-8" style={{maxWidth:"100%"}}>
                    <div className="middle-box">
                        <div className="card mb-0">
                            <div className="card-body p-4">
                                <div className="row align-items-center">
								 <div className="col-md-6">
                                        <div className="xs-d-none">
                                             {/* <img src="img/core-img/logo.png" alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                      
                                        <h4 className="font-22 mb-30 text-center">Sign In</h4>

                                        <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleLogin}
                                        >
                                        {({ isSubmitting }) => (
                                            <Form className="form" id="login_form">
                                            <div className="form-group">
                                                <label className="float-left" htmlFor="username">Username</label>
                                                <Field className="form-control" type="text" id="username" name="username" placeholder="Enter your Username" />
                                                <ErrorMessage name="username" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="float-left" htmlFor="password">Password</label>
                                                <Field className="form-control" type="password" id="password" name="password" placeholder="Enter your password" />
                                                <ErrorMessage name="password" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group mb-3">
                                                <div className="custom-control custom-checkbox pl-0">
                                                <div className="custom-control custom-checkbox">
                                                    <Field type="checkbox" className="custom-control-input" id="remember" name="remember" />
                                                    <label className="custom-control-label" htmlFor="remember"><span className="font-16">Remember me</span></label>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0 text-center">
                                                <button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting}> Log In </button>
                                            </div>

                                            </Form>
                                        )}
                                        </Formik>
                                    </div>
									
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>:navigate('/dashboard')
  );
}
